

import React, { useCallback, Component, Fragment } from "react";

import { Layout, Icon, Avatar, Switch, Spin, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';

import 'antd/dist/antd.css';

import '../../../scss/template.scss';

import '../../../scss/Dashboard.scss';

import '../../../scss/slot.scss';

import { Alert } from '../../Layout/Files';

import { Uploads } from './Uploads';

import { Update } from './Update';

import VipSvg from '../../../vip.svg';

import NOR from '../../../normal.svg';

import EMR from '../../../emr.svg';

import DIR from '../../../dir.svg';

import DIS from '../../../dis.svg';
import TRK from '../../../truck.svg';

import { GET_SLOT, NO_SLOTS, FIND_SLOT, DISABLEACTIVE_SLOT, DELETE_SLOT, SEARCH_SLOT } from '../../../graphql/slot';

import { client } from "../../../graphql/Client";

import { TemplateHeader, ens } from '../../Layout/TemplateHeader';

import { strings } from "../../Layout/Strings";

import { string } from "prop-types";

const InputGroup = Input.Group;


class Slot_Manage extends React.Component {

    state = {

        slot_model: false,

        slot_update: false,

        slot_no: '',

        slot_name: '',

        category: '',

        description: '',

        file: '',

        imageUrl: '',

        dataSource: [],

        data: [],

        emergency_slot: '',

        normal_Slot: '',

        no_Slot: '',

        disable_Slot: '',

        active_Slot: '',
        inactive_Slot: '',

        director_Slot: '',
        truck_Slot:'',

        vp_Slot: '',

        loading: 1,

        en: 0,
        ss_name:'',
        ss_no:'',
    };

    componentDidMount() {

        this.fetch_Slot();

        this.no_Slot();

    }

    // fecth user
    fetch_Slot = () => {

        client.query({

            query: GET_SLOT,

            fetchPolicy: 'no-cache',

        }).then(result => {

            console.log(result.data.slots);

            this.setState({ loading: false, slot_model: 0, slot_update: 0, dataSource: result.data.slots });

        });

    }

    // fecth user
    no_Slot = () => {

        client.query({

            query: NO_SLOTS,

            fetchPolicy: 'no-cache',

        }).then(result => {

            console.log(result.data);

            this.setState({

                emergency_slot: result.data.no_slots[0].emergency_slot,

                normal_Slot: result.data.no_slots[0].normal_slot,

                no_Slot: result.data.no_slots[0].no_slot,

                inactive_Slot: result.data.no_slots[0].disable_slot,

                disable_Slot: result.data.no_slots[0].dis_slot,

                active_Slot: result.data.no_slots[0].acitve_slot,

                director_Slot: result.data.no_slots[0].director_slot,

                vp_Slot: result.data.no_slots[0].vp_slot,
                truck_Slot: result.data.no_slots[0].truck_slot,


            });

        });
    }

    cancelModel = async (data) => {

        console.log(data);

        this.setState({ slot_model: data });

    }

    // update user
    updateUser = async (data) => {

        console.log(data);

        Alert(data.updateSlot);

        this.setState({ slot_update:0});

        this.fetch_Slot(false);

    }

    addSlot = async (data) => {

        console.log(data.addSlot);

        Alert(data.addSlot);

        this.setState({slot_model:0});

        this.fetch_Slot();

        this.no_Slot();

       


    }

    //edit slot

    editSlot = async (_id) => {

        console.log(_id);

        await client.query({

            query: FIND_SLOT,

            variables: { _id: _id },

            fetchPolicy: 'no-cache',

        }).then(result => {

            console.log(result.data);

            this.setState({ data: result.data.slots, slot_update: true });

        });
    }

    disableactiveSlot = async (value, _id) => {

        console.log(value, _id);

        if (value === false) {

            value = 'disable';

        } else {

            value = 'active';

        }

        await client.query({

            query: DISABLEACTIVE_SLOT,

            variables: { _id: _id, status: value, lang: ens },

            fetchPolicy: 'no-cache',

        }).then(result => {

            console.log(result.data);

            Alert(result.data.disable_active);

        });

        this.fetch_Slot();

    }


    deleteSlot = async (_id) => {

        if (_id != '') {

            await client.query({

                query: DELETE_SLOT,

                variables: { _id: _id, lang: ens },

                fetchPolicy: 'no-cache',

            }).then(result => {

                console.log(result.data);

                Alert(result.data.delete_slot);

                this.fetch_Slot();

                this.no_Slot();

                
            });

        }
    }

    res_search = value => {

        client.query({

            query: SEARCH_SLOT,

            variables: { lang: ens, sname: this.state.ss_name,sno:this.state.ss_no },

            fetchPolicy: 'no-cache',

        }).then(result => {

            console.log(result.data);
            if (result.data.serach_slot.length != 0) {

                this.setState({ dataSource: result.data.serach_slot  });

            } else {

                Alert({ msg: "INVALID DATA", status: 'failed' });

            }

        });
    }


    render() {

        strings.setLanguage(ens);

        const { normal_Slot, no_Slot, vp_Slot, disable_Slot, active_Slot, director_Slot, emergency_slot, inactive_Slot,truck_Slot } = this.state;

        const gridStyle = {

            width: '11em',

            padding: '0px',

            height: '7em',

        };

        const detailStyle = {

            width: '100%',

            padding: '0.5em 1em',

        };


        return (

            <div>

                <Modal

                    title={strings.ads}

                    style={{ top: 20 }}

                    visible={this.state.slot_model}

                    onCancel={() => this.setState({ slot_model: false })}

                    footer={[]} >

                    <Uploads addslot={this.addSlot} cancelmodel={this.cancelModel} />

                </Modal>

                <Modal

                    title={strings.ups}

                    style={{ top: 20 }}

                    visible={this.state.slot_update}

                    onCancel={() => this.setState({ slot_update: false })}

                    footer={[]} >

                    <Update updateuser={this.updateUser} data={this.state.data} cancelmodel={(data) => { this.setState({ slot_update: data }) }} />

                </Modal>

                <Row gutter={16}>

                    <InputGroup compact className="mx-3 py-4">
                    
                        <Input style={{ width: 'max-content' }} placeholder={strings.sln} size="small"  onChange={(event)=>{this.setState({ss_name:event.target.value})}}/>
                    
                        <Input style={{ width: 'max-content' }} placeholder={strings.slno} size="small" onChange={(event) => { this.setState({ss_no:event.target.value})}} />
                    
                        <Button type="primary" icon="search" size="small" onClick={this.res_search}> Search </Button>
                      
                        <Button className="ml-4" type="dashed" size="small" onClick={this.fetch_Slot}>ALL SLOTS</Button>

                    </InputGroup>

                    <Col sm={24} lg={16}>

                        <Card loading={this.state.loading} title={<span>{strings.as}
                        </span>} extra={<div data-toggle="tooltip" data-placement="left" title="ADD SLOT" style={{ fontSize: '1.5em' }}> <Icon type="plus-circle" onClick={() => this.setState({ slot_model: true })} /> </div>}>

                            {this.state.dataSource.map((key, value) => {

                                return (

                                    <Card.Grid style={gridStyle} className="d-block align-items-start flex-column"  >

                                        <Row>

                                            <Col span={24}>

                                                <img className="float-right" src={key.category === 'VP' ? VipSvg :
                                                    key.category === 'DIRECTOR' ? DIR :
                                                        key.category === 'NORMAL' ? NOR :
                                                            key.category === 'DISABLED' ? DIS :
                                                                key.category === 'TRUCK' ? TRK :
                                                                    key.category === 'emergency' ? EMR : ''
                                                } style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />

                                            </Col>

                                        </Row>

                                        <div className="d-flex" style={{ margin: '.8em' }}>

                                            <div className="mx-auto">{key.slot_name}_{key.slot_no}</div>

                                        </div>

                                        <div class="d-flex justify-content-between" style={{ width: '-webkit-fill-available' }}>

                                            <div class="" >

                                                <Icon type="edit" tabindex='-1' className="float-left m-auto" onClick={() => { this.editSlot(key._id) }} />

                                            </div>

                                            <div class="" >

                                                <Switch size="small" defaultChecked={key.status === 'disable' ? 0 : 1} onChange={(value) => this.disableactiveSlot(value, key._id)} />

                                            </div>

                                            <div class="">

                                                <Icon type="delete" tabindex='-1' className="float-right" onClick={() => { this.deleteSlot(key._id) }} />

                                            </div>

                                        </div>

                                    </Card.Grid>
                                )

                            })}

                        </Card>

                    </Col>

                    <Col sm={24} lg={8} className="pt-lg-0 pt-3">

                        <Card className="slot_detail" title={<span>{strings.sd} </span>} extra={<span>{strings.COUNT}</span>} >

                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.noas}</span>

                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right"> {no_Slot} </span>

                                    </Col>

                                </Row>

                            </Card.Grid>

                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.noaa}</span>


                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{active_Slot}</span>

                                    </Col>

                                </Row>

                            </Card.Grid>

                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.noad}</span>

                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{inactive_Slot}</span>

                                    </Col>

                                </Row>

                            </Card.Grid>


                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.noav}</span>

                                        <img className="float-right" src={VipSvg} style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />


                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{vp_Slot} </span>

                                    </Col>

                                </Row>

                            </Card.Grid>

                            <Card.Grid style={detailStyle}>


                                <Row>

                                    <Col span={20}>

                                        <span>{strings.nods}</span>

                                        <img className="float-right" src={DIR} style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />

                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{director_Slot}</span>

                                    </Col>
                                    

                                </Row>

                            </Card.Grid>

                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.noan}</span>

                                        <img className="float-right" src={NOR} style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />


                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{normal_Slot}</span>

                                    </Col>

                                </Row>

                            </Card.Grid>

                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.noae}</span>

                                        <img className="float-right" src={EMR} style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />


                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{emergency_slot}</span>

                                    </Col>

                                </Row>

                            </Card.Grid>


                            <Card.Grid style={detailStyle}>

                                <Row>

                                    <Col span={20}>

                                        <span>{strings.nodis}</span>

                                        <img className="float-right" src={DIS} style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />


                                    </Col>

                                    <Col span={4}>

                                        <span className="float-right">{disable_Slot}</span>

                                    </Col>

                                </Row>

                            </Card.Grid>
                            <Card.Grid style={detailStyle}>


                                <Row>

                                    <Col span={20}>

                                        <span>{strings.nots}</span>

                                        <img className="float-right" src={TRK} style={{ height: '1.5em', padding: '2px', margin: '5px', }} alt={'ESSITY'} />

                                    </Col>


                                    <Col span={4}>

                                        <span className="float-right">{truck_Slot}</span>

                                    </Col>
                                    

                                </Row>

                            </Card.Grid>

                        </Card>

                    </Col>

                </Row>

            </div >

        );
    }
}

export default Slot_Manage;
