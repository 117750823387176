import LocalizedStrings from 'react-localization';
export const strings = new LocalizedStrings({
  en: {
    "DASHBOARD": "DASHBOARD",
    "USER": "USER",
    "ADMIN": "ADMIN",
    "SETTINGS": "SETTINGS",
    "SLOT": "PARKING SLOT",
    "BOOKING": "BOOKINGS",
    "REPORT": "REPORT",
    "PENALTY": "PENALTY",
    "HOLIDAY": "HOLIDAY",
    "STATIC": "STATIC",
    "ARCHIVE":"ARCHIVE",
    "ru": "REPORTED USERS",
    "pu": "PENALISTED USERS",
    "as": "ALL SLOT",
    "ads": "ADD SLOT",
    "sd": "SLOT DETAIL",
    "noav": "NO OF VP SLOT",
    "noan": "NO OF NORMAL SLOT",
    "nods": "NO OF DIRECTOR SLOT",
    "nots": "NO OF TRUCK SLOT",
    "nodis": "NO OF DISABLED SLOT ",
    "noae": "NO OF EMERGENCY SLOT",
    "noas": "NO OF SLOT",
    "noaa": "NO OF ACTIVE SLOT",
    "noad": "NO OF INACTIVE SLOT",
    "pl": "PENALTY LEVEL",
    "reson": "RESON",
    "du": "DURATION",
    "apd": "ADD PENALTY DETAIL",
    "upd": "UPDATE PENALTY DETAIL",
    "au": "ADD USER",
    "psd": "PENALTY START DATE",
    "ped": "PENALTY END DATE",
    "vdr": "VIEW DETAIL REPORT",
    "ups": "UPDATE SLOT",
    "uls": "UPLOAD SLOT",
    "sln": "SLOT NAME",
    "slno": "SLOT NO",
    "COUNT": "COUNT",
    "rst": "REPORT STATUS",
    "bs": "BOOKING STATUS",
    "rcat": "REPORT CATEGORY",
    "DETAIL": "DETAIL",
    "date": "DATE",
    "NAME": "NAME",
    "book_rs":"BOOKING RESERVATION",
    "rep_rs": "REPORT RESERVATION",
    "rs": "RESERVATION",
    "st": "STATUS",
    "cat": "CATEGORY",
    "ll": "LEVEL",
    "START": "START",
    "END": "END",
    "ds": "DESCRIPTION",
    "ti": "TITLE",
    "adsc": "ADD STATIC CONTENT",
    "email": "EMAIL",
    "ut": "USER TYPE",
    "un": "USER NAME",
    "sn": "SURE NAME",
    "vlp": "VEHICLE LICENSE PLATE",
    "ph": "PHOTO",
    "pw": "PASSWORD",
    "vc": "VEHICLE COLOR",
    "vno": "VEHICLE NUMBER",
    "vm": "VEHICLE MODEL",
    "CANCEL": "CANCEL",
    "SUBMIT": "SUBMIT",
    "UPDATE": "UPDATE",
    "LOGOUT": "LOGOUT",
    "ct": "CONTENT",
    "other": "OTHER",
    "ah": "ADD HOLIDAY",
    "uh": "UPDATE HOLIDAY",
    "CHART1": "Occupation based on bookings",
    "CHART2": "Bookings by user",
    "CHART3": "Reported parking spaces (lightning, leaks, obstacles)",
    "CHART4": "User Statistics",
    "CHART5": "Bookings And Canceled Data (Fixed slots)",
    "CHART6": "Total bookings ",
    "rd": "REPORT DETAIL",
    "pu": "PENALTY USER",
    "ui": "USER IMAGE",
    "ri": "REPORT IMAGE",
    "vd": "VECHILE DETAIL",
    "rds": "REPORT DETAIL STATUS",
    "ps": "PENALTY STATUS",
    "pc": "PENALTY COUNT",
    "an": "ADMIN NAME",
    "employee_number": "EMPLOYEE NUMBER",
    "card_family":"CARD FAMILY",
    "card_number":"CARD NUMBER"
  },
  es: {
    "DASHBOARD": "TABLERO",
    "an": "NOMBRE ADMINISTRADOR",
    "USER": "USUARIA",
    "ADMIN": "Administración",
    "SETTINGS": "AJUSTES",
    "SLOT": "ESPACIO",
    "BOOKING": "RESERVA",
    "REPORT": "INFORME",
    "PENALTY": "PENA",
    "HOLIDAY": "VACACIONES",
    "STATIC": "ESTÁTICA",
    "ARCHIVE":"ARCHIVO",
    "sd": "DETALLE DE RANURA",
    "ru": "REPORTAR USUARIO",
    "pu": "PENA USUARIO",
    "as": "TODA LA RANURA",
    "ads": "AGREGAR RANURA",
    "noav": "NO DE VP SLOT",
    "noan": " NO DE RANURA NORMAL",
    "nods": "NO DE LA RANURA DEL DIRECTOR",
    "nots": "NÚMERO DE ESPACIOS PARA CAMIÓN",
    "nodis": "NO DE RANURA DESACTIVADA",
    "noae": " NO DE RANURA DE EMERGENCIA",
    "noas": "NO DE RANURA",
    "noaa": " NO DE RANURA ACTIVA",
    "noad": "NO DE RANURA INACTIVA",
    "pl": "NIVEL DE PENALIZACIÓN",
    "reson": "RESON",
    "vno": "NÚMERO DE VEHÍCULO",
    "du": "DURATION",
    "apd": "AGREGAR DETALLE DE PENALIZACIÓN",
    "upd": " ACTUALIZAR DETALLE DE PENALIZACIÓN",
    "au": "AGREGAR USUARIO",
    "psd": " FECHA DE INICIO DE PENALIZACIÓN",
    "ped": " FECHA DE FINALIZACIÓN DE LA PENA",
    "vdr": "VER INFORME DETALLADO",
    "ups": " Ranura de actualización",
    "uls": "CARGAR RANURA",
    "sln": "Nombre de la ranura",
    "slno": "Ranura NO",
    "COUNT": "CONTAR",
    "rst": "REPORT STATUS",
    "bs": "ESTADO DE LA RESERVACIÓN",
    "rcat": "CATEGORÍA DE INFORME",
    "DETAIL": "DETALLE",
    "date": "FECHA",
    "NAME": "NOMBRE",
    "book_rs": "RESERVA DE RESERVA",
    "rep_rs": "RESERVA DE INFORMES",
    "rs": "RESERVA",
    "st": "ESTADO",
    "cat": "CATEGORÍA",
    "ll": "NIVEL",
    "START": "COMIENZO",
    "END": "FINAL",
    "ds": "DESCRIPTION",
    "ti": "TÍTULO",
    "adsc": " AGREGAR CONTENIDO ESTÁTICO",
    "email": "CORREO ELECTRÓNICO",
    "ut": "TIPO DE USUARIO",
    "un": "NOMBRE DE USUARIO",
    "sn": "NOMBRE SEGURO",
    "vlp": "PLACA DE LICENCIA DE VEHÍCULO",
    "ph": "FOTO",
    "pw": "CONTRASEÑA",
    "vc": "COLOR DE VEHICULO",
    "vm": "MODELO DE VEHÍCULO",
    "CANCEL": "CANCELAR",
    "SUBMIT": "ENVIAR",
    "UPDATE": "ACTUALIZAR",
    "LOGOUT": "CERRAR SESIÓN",
    "ct": "CONTENIDO",
    "other": "OTRA",
    "ah": "AGREGAR VACACIONES",
    "uh": "ACTUALIZAR VACACIONES",
    "CHART1": "Ocupación basada en reservas",
    "CHART2": "Reservas por usuario",
    "CHART3": "Estacionamiento reportado (rayos, fugas, obstáculos)",
    "CHART4": "Estadísticas de usuario",
    "CHART5": "Bookings And Canceled Data (Fixed slots)",
    "CHART6": "Total bookings",
    "rd": "DETALLE DEL INFORME ",
    "pu": "USUARIO DE PENALIZACIÓN ",
    "ui": "IMAGEN DEL USUARIO ",
    "ri": "REPORTAR IMAGEN ",
    "vd": "DETALLES DEL VEHÍCULO ",
    "rds": " INFORME DETALLE ESTADO",
    "ps": "ESTADO DE PENALIZACIÓN",
    "pc": "CUENTA DE PENALIZACIÓN",
    "employee_number": "NÚMERO DE EMPLEADO",
    "card_family":"FAMILIA DE TARJETAS",
    "card_number":"NÚMERO DE TARJETA"
  }
});