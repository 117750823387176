

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import gql from "graphql-tag";
import { Layout, Icon, Badge, Avatar, Form, Input,Spin, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';
import { useMutation } from "@apollo/react-hooks";
import { Alert } from '../../Layout/Files';
import {ens} from '../../Layout/TemplateHeader';
import {strings} from '../../Layout/Strings';
import ImageCompressor from 'image-compressor.js';

const { TextArea } = Input;

const { Option } = Select;

const ADD_SLOT = gql`

    mutation ADDSLOT($slot_name: String, $slot_no: Int, $description: String ,$category: String ,$file:[Upload],$file1:[Upload],$lang:String) {   
      addSlot(slot_name: $slot_name,slot_no: $slot_no, description: $description,file:$file,file1:$file1,category: $category,lang:$lang)
        {
            _id,
            slot_name,
            slot_no,
            description,
            category,
            photo,
            msg,
            status
        }
    }
`;

export const Uploads = (props) => {

    const [slot_no, setslot_no] = React.useState('');

    const [slot_name, setslot_name] = React.useState('');

    const [description, setdescription] = React.useState('');

    const [category, setcategory] = React.useState('');

    const [file, setFile] = React.useState('');

    const [file1, setFile1] = React.useState('');

    const [imageUrl, setimageUrl] = React.useState('');

    const [imageUrl1, setimageUrl1] = React.useState('');

    const [up1, setupt] = React.useState('');

    const [spin, setspin] = React.useState(0);

    const [uploadFile] = useMutation(ADD_SLOT, {});


    const getBase64 = (file) => {

        var reader = new FileReader();

        reader.readAsDataURL(file[0]);

        reader.onload = () => {

            console.log(reader.result);

            setimageUrl(reader.result);

        };

        reader.onerror = (error) => {

            console.log('Error: ', error);

        };

    }

    const getBase641 = (file) => {

        var reader1 = new FileReader();

        reader1.readAsDataURL(file[0]);

        reader1.onload = () => {

            setimageUrl1(reader1.result);

        };

        reader1.onerror = (error) => {

            console.log('Error: ', error);

        };

    }

    const onDrop = async acceptedFiles => {

            console.log(acceptedFiles);
            console.log(file);

            setupt('1');
            
            if(file.length === 1){

                await getBase641(acceptedFiles);

                await new ImageCompressor(acceptedFiles[0], {

                    quality: .6,
                    success(result) {
                        console.log(result);
                        var file = new File([result], result.name);
                        console.log(file);
                        setFile1(file);
                    }
                });
                // setFile1(acceptedFiles);

            } else if (file.length === 0){
                
                await getBase64(acceptedFiles);

                await new ImageCompressor(acceptedFiles[0], {

                    quality: .6,
                    success(result) {
                        console.log(result);
                        var file = new File([result], result.name);
                        console.log(file);
                        setFile([file]);
                    }
                });
                // setFile(acceptedFiles);

            }

           
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: 0});


    const onChange = event => setslot_name(event.target.value);

    const onChange1 = event => setslot_no(Number(event.target.value));

    const onChange2 = event => setdescription(event.target.value);

    const onChange3 = value => setcategory(value);

    const oncancel = () => {

        props.cancelmodel(false);

    }

    const AddSlot = useCallback(

        (event) => {

            console.log(file);

            setspin(1);

            if (slot_no !== '' && slot_name !== '' && category !== '') {

                // this.props.

                uploadFile({

                    variables: {

                        "category": category, "slot_no": slot_no, "slot_name": slot_name, "description": description, file, file1, lang: ens

                    }

                }).then(result => {

                    setspin(0);

                    console.log(result.data);

                    props.addslot(result.data);

                });

            } else {

                setspin(0);

                Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

            }
        },

        [uploadFile]
    );

    strings.setLanguage(ens);

    return (
        <div className="gutter-example">

            <Spin tip="Loading..." className={spin === 0 ?'d-none':''}  > </Spin>
          
            <Row gutter={16}>

                <Col className="gutter-row" span={24}>

                    <Row gutter={16}>

                        <Col className="gutter-row" span={8}>

                            <div className='d-flex' style={{ marginBottom: 16 }}>

                                <Badge status="processing" text="" />

                                <Input placeholder={strings.sln} value={slot_name} onChange={onChange} />

                            </div>

                        </Col>

                        <Col className="gutter-row" span={8}>

                            <div className='d-flex' style={{ marginBottom: 16 }}>

                                <Badge status="processing" text="" />

                                <Input placeholder={strings.slno} value={slot_no} onChange={onChange1} />

                            </div>

                        </Col>
                        <Col className="gutter-row" span={8}>

                            <div className='d-flex' style={{ marginBottom: 16 }}>

                                <Badge status="processing" text="" />

                                <Select defaultValue={strings.cat} style={{ width: '-webkit-fill-available' }} onChange={onChange3}>

                                    <Option value="VP">VP</Option>

                                    <Option value="DIRECTOR">DIRECTOR</Option>

                                    <Option value="NORMAL"> NORMAL </Option>

                                    <Option value="emergency"> EMERGENCY </Option>

                                    <Option value="DISABLED"> DISABLED </Option>
                                    <Option value="TRUCK"> TRUCK </Option>

                                </Select>

                            </div>

                        </Col>

                    </Row>

                    <Row gutter={16}>

                        <Col className="gutter-row" span={16}>

                            <div style={{ marginBottom: 16 }}>

                                <TextArea placeholder={strings.ds} value={description} onChange={onChange2} rows={6} />

                            </div>

                        </Col>

                        <Col className="gutter-row" span={8}>

                            <div className="d-flex" style={{ marginBottom: 16 }}>

                                <Icon type="caret-left" className={up1 === '1' ? 'd-none' : up1 === '' ? 'd-none' : 'align-self-center'} onClick={() => { setupt('1') }} />

                                <div className={up1 === '1' ? 'vw-100 gutter-box' : up1 === '' ? 'vw-100 gutter-box' : 'd-none'}>

                                    <div {...getRootProps()} className={imageUrl === '' ? "slot_img_upload d-flex" : "d-none"} >

                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop the files here ...</p>
                                        ) : (

                                                <Icon type="cloud-upload" className="m-auto icon" />
                                            )}
                                    </div>

                                    <img src={imageUrl} className={imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '8.5em' }} />

                                    <Icon type="delete" theme="twoTone" className={imageUrl === '' ? "d-none" : "m-auto d-block"} onClick={() => { setimageUrl(''); setFile(''); }} />

                                </div>

                                <div className={up1 === '2' ? 'vw-100 gutter-box' : 'd-none'}>

                                    <div {...getRootProps()} className={imageUrl1 === '' ? "slot_img_upload d-flex" : "d-none"} >

                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop the files here ...</p>
                                        ) : (

                                                <Icon type="cloud-upload" className="m-auto icon" />
                                            )}
                                    </div>

                                    <img src={imageUrl1} className={imageUrl1 === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '8.5em' }} />

                                    <Icon type="delete" theme="twoTone" className={imageUrl1 === '' ? "d-none" : "m-auto d-block"} onClick={() => { setimageUrl1(''); setFile1(''); }} />

                                </div>


                                <Icon type="caret-right" className={up1 === '2' ? 'd-none' : up1 === '' ? 'd-none' : 'align-self-center'} onClick={() => { setupt('2') }} />

                            </div>

                        </Col>

                    </Row>

                    <Row>

                        <Col>

                            <Button key="back" onClick={oncancel}> Cancel </Button>

                            <Button className="float-right" key="submit" type="primary" onClick={AddSlot}> Submit </Button>

                        </Col>

                    </Row>

                </Col>

            </Row>

        </div>

    );
};
